import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'


export type HeadlineBlockProps = {
  className?: string
  supTitle?: string | Intl.Message
  title: string | Intl.Message
  text?: string | Intl.Message
}

const HeadlineBlock: React.FunctionComponent<HeadlineBlockProps> = (props) => {
  const { className, supTitle, title, text } = props

  const { isMobile } = useDevice()

  return (
    <div className={cx(className, 'mx-auto mb-32 max-w-752 text-center')}>
      {
        Boolean(supTitle) && (
          <Text className="mb-4" message={supTitle} style="eye2" color="gold-70" />
        )
      }
      <Text message={title} style={isMobile ? 'h5' : 'h4'} html />
      {
        Boolean(text) && (
          <Text className="mt-16" message={text} style="p2" html />
        )
      }
    </div>
  )
}


export default HeadlineBlock
