import React from 'react'
import cx from 'classnames'
import { Disclosure as DisclosureBase } from '@headlessui/react'

import { Icon, Text } from 'components/dataDisplay'

import s from './Disclosure.module.css'


export const titleStyles = [ 'p2', 'sh6' ] as const

export type DisclosureProps = {
  className?: string
  title: Intl.Message | string
  titleStyle: typeof titleStyles[number]
  text: Intl.Message | string
  onClick?: () => void
  'data-testid'?: string
}

const Disclosure: React.CFC<DisclosureProps> = (props) => {
  const { children, className, title, text, titleStyle, 'data-testid': dataTestId, onClick } = props

  return (
    <DisclosureBase className={cx(s.root, className)} as="div" onClick={onClick} data-testid={dataTestId}>
      {
        ({ open }) => (
          <>
            <DisclosureBase.Button className="flex w-full cursor-pointer items-center justify-between py-16 text-black hover:text-gold-30">
              <Text message={title} style={titleStyle} />
              <Icon className={cx(s.icon, open && s.activeIcon)} name="24/plus" color="gold-50" isInline />
            </DisclosureBase.Button>
            <DisclosureBase.Panel className="pb-24">
              {
                text ? (
                  <Text message={text} style="p3" html tag="div" />
                ) : (
                  children
                )
              }
            </DisclosureBase.Panel>
          </>
        )
      }
    </DisclosureBase>
  )
}


export default Disclosure
