import React, { useMemo } from 'react'

import { getRichTextStateFromMessage } from 'modules/strapi'
import { Text } from 'components/dataDisplay'

import { renderNestedNodes, getNodeClassnames } from './util'


export type RichTextProps = {
  className?: string
  children?: React.ReactNode
  message?: string | Strapi.RichTextState
  'data-testid'?: string
}

const RichText = React.forwardRef<HTMLDivElement, RichTextProps>((props, ref) => {
  let { className, children, message, 'data-testid': dataTestId } = props

  const formattedBlock = useMemo(() => {
    const editorState = typeof message === 'string' ? getRichTextStateFromMessage(message) : message

    if (!editorState?.root?.children) {
      return null
    }

    return editorState.root.children.map((node, index) => {
      const { type, style, children } = node

      const classNames = getNodeClassnames(node)
      const formattedText = renderNestedNodes(children)

      if (type === 'linebreak' || !formattedText) {
        return <br key={index} />
      }

      return (
        <Text
          key={index}
          style={style}
          className={classNames}
          message={formattedText}
          html
        />
      )
    })
  }, [ message ])

  return (
    <div ref={ref} className={className} data-testid={dataTestId}>
      {formattedBlock?.map((block) => block)}
      {children}
    </div>
  )
})


export default RichText
