const BOLD_FORMAT_NUMBER = 1
const UNDERLINE_FORMAT_NUMBER = 1 << 3

const wrapNode = ({ content, format, fontColor, url, topIndent }) => {
  const styles = getNodeClassnames({ format, fontColor, topIndent })

  if (url) {
    return `<Href className="${styles}" to="${url}">${content}</Href>`
  }

  return styles ? `<span class="${styles}">${content}</span>` : content
}

export const getNodeClassnames = ({ format, fontColor, topIndent }) => {
  let styles = []

  if (topIndent) {
    styles.push(`mt-${topIndent}`)
  }

  if (fontColor) {
    styles.push(`text-${fontColor}`)
  }

  if (format === 'left') {
    styles.push('text-left')
  }

  if (format === 'right') {
    styles.push('text-right')
  }

  if (format === 'center') {
    styles.push('text-center')
  }

  if ((format & BOLD_FORMAT_NUMBER) !== 0) {
    styles.push('font-bold')
  }

  if ((format & UNDERLINE_FORMAT_NUMBER) !== 0) {
    styles.push('underline')
  }

  return styles.join(' ')
}

export const renderNestedNodes = (nodes) => {
  return nodes?.reduce((blockText, node) => {
    const { type, children, format, text, fontColor, url, topIndent } = node

    const hasStyles = Boolean(format || fontColor || topIndent)
    const hasWrapper = hasStyles || Boolean(url)
    const hasNestedNodes = Boolean(text || children)

    if (hasWrapper || hasNestedNodes) {
      const content = children ? renderNestedNodes(children) : text

      return blockText + wrapNode({ url, format, fontColor, content, topIndent })
    }

    if (!text || type === 'linebreak') {
      return blockText + '<br />'
    }

    return blockText + text
  }, '')
}
