import React, { forwardRef } from 'react'

import { Href } from 'components/navigation'
import { Label, RichText } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'
import type { ActionButtonProps } from 'components/inputs'


export type RichTextPromoCardContainerProps = {
  className?: string
  children: React.ReactElement
  message: string
  label?: string
  indent?: 12 | 14 | 16 | 18 | 20 | 24 | 32 | 36 | 40 | 48 | 56
  action?: Pick<ActionButtonProps, 'title' | 'to' | 'toTab' | 'href' | 'onClick'>
}

const RichTextPromoCardContainer = forwardRef<any, RichTextPromoCardContainerProps>((props, ref) => {
  const { children, className, message, label, action, indent = 16 } = props

  const messageContent = (
    <div className="relative" style={{ marginTop: `-${indent}rem` }}>
      <RichText message={message} />
    </div>
  )

  if (action) {
    const { to, toTab, href, onClick } = action

    return (
      <Href
        className={className}
        to={to}
        toTab={toTab}
        href={href}
        onClick={onClick}
        ref={ref}
      >
        {children}
        <div className="relative pl-16">
          {
            Boolean(label) && (
              <Label
                className="!absolute !bottom-[calc(100%+16rem)] left-0"
                richText={label}
                bgColor="black"
              />
            )
          }

          {messageContent}
          <ActionButton
            className="mt-16"
            title={action.title}
            style="gold-50"
          />
        </div>
      </Href>
    )
  }

  return (
    <div className={className} ref={ref}>
      {children}
      {messageContent}
    </div>
  )
})


export default React.memo(RichTextPromoCardContainer)
