import { startTransition, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'
import logger from 'logger'

import NativeImage from '../NativeImage/NativeImage'


const useIsomorphicEffect = __CLIENT__ ? useLayoutEffect : useEffect

const getPlaceholder = (aspect = 1) => `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${aspect} 1'%3e%3c/svg%3e`


const NativeImageWrapper = (props: Image.Props) => {
  const nodeRef = useRef<HTMLImageElement>()
  const [ sizes, setSizes ] = useState<string>(props.sizes)
  const aspect = typeof props.src === 'object' && props.src.height > 0 ? (props.src.width / props.src.height) : 1

  useIsomorphicEffect(() => {
    const node = nodeRef?.current

    if (!node) {
      return
    }

    let isMounted = true
    let lastWidth = null

    const update = () => {
      if (!isMounted) {
        return
      }

      const width = node.clientWidth

      if (lastWidth < width) {
        const sizes = `${Math.ceil(width)}px`

        if (__DEV__) {
          console.log(`set image sizes ${sizes}`, node)
        }

        startTransition(() => {
          setSizes(sizes)
        })
      }

      lastWidth = width
    }

    const debouncedUpdate = debounce(() => update(), 100)

    update()

    let resizeObserver: ResizeObserver
    try {
      if (typeof ResizeObserver !== 'undefined') {
        resizeObserver = new ResizeObserver(debouncedUpdate)
        resizeObserver.observe(node)
      }
    }
    catch (error) {
      logger.warn(error)
    }

    return () => {
      isMounted = false

      if (resizeObserver) {
        resizeObserver.unobserve(node)
        resizeObserver.disconnect()
        debouncedUpdate.cancel()
      }
    }
  }, [])

  return (
    <NativeImage
      {...props}
      ref={nodeRef}
      sizes={sizes}
      src={sizes ? props.src : getPlaceholder(aspect)}
    />
  )
}


export default NativeImageWrapper
